/* 
 * PYD js lib
 */

// namespaces
var pyd = pyd || {};
pyd.ajax = pyd.ajax || {};
pyd.ui = pyd.ui || {};
pyd.element = pyd.element || {};

/**
 * Format a message from an ajax response returning an exception and display
 * it in an alert.
 * 
 * This methode handles response as a string containing text or json. This is
 * mostly to be used in an ajax.failure method.
 * 
 * @param {XmlHttpRequest} xhr
 * @param {string} headerMessage text displayed as a header in the alert box
 * @return {undefined}
 */
pyd.ajax.alertFailureResponse = function (xhr, headerMessage) {
    var message = '';
    if (undefined !== headerMessage) {
        message = headerMessage;
    }
    var responseText = xhr.responseText;
    
    if (-1 === responseText.indexOf('{')) {
        message += '\r\n' + responseText;
    } else {
        var responseJson = JSON.parse(responseText);
        if (responseJson.hasOwnProperty('name')) {
            message += '\r\n' + responseJson.name + ' (#' + xhr.status + ')';
        } else {
            message += '\r\nCode http' + xhr.status;
        }
        if (responseJson.hasOwnProperty('message')) {
            message += '\r\n' + responseJson.message;
        }
    }
    alert(message);
};

/*
 * 
 * @param {Element} element
 * @param {type} style
 * @return {undefined}
 */
pyd.element.applyStyle = function (element, style) {
    for (var prop in style) {
        element.style[prop] = style[prop];
    }
};

pyd.element.centerInParent = function (element, parent) {
        element.style.marginLeft = (parent.offsetWidth - element.offsetWidth) / 2;
        element.style.marginTop = (parent.offsetHeight - element.offsetHeight) / 2;
};

/*
 * Manage an 'overlay' element i.e. a div that covers the page content to
 * disable interaction with it.
 * 
 * @return {pyd.ui.Overlay}
 */
pyd.ui.Overlay = function () {
    
    /*
     * Element used as a background to cover the window
     * @type {Element}
     */
    var container = null;
    /*
     * CSS 'id' of the overlay container.
     * @type {String}
     */
    var containerId = "overlay";
    /*
     * CSS style of the overlay container.
     * @type {json}
     */
    var containerStyle = {
        display: "none",
        "z-index": "999",
        background: "rgba(0,0, 0, 0.7)",
        position: "fixed",  // cover even when scrolling
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
    };
    /*
     * Element displaying a message in the center of the screen.
     * @type {Element}
     */
    var messageBox = null;
    
    var messageBoxStyle = {
        position: "relative",
        top: "10%",
        left: "25%",
        width: "50%",
        padding: "20px",
        "border-radius": "5px",
        fontSize: "16px",
    };
    
    /*
     * Constructor.
     * 
     * Add container and its messageBox to the DOM.
     */
    var __construct = function () {
        
        container = document.createElement('div');
        container.id = containerId;
        pyd.element.applyStyle(container, containerStyle);
        document.body.appendChild(container);
        
        messageBox = document.createElement('div');
        pyd.element.applyStyle(messageBox, messageBoxStyle);
        container.appendChild(messageBox);
        
        closeButton = document.createElement('button');
        closeButton.setAttribute("type", "button");
        closeButton.className = 'close';
        closeButton.setAttribute('data-dismiss', 'alert');
        closeButton.setAttribute('aria-label', 'Close');
        closeButtonSpan = document.createElement('span');
        closeButtonSpan.setAttribute('aria-hidden', 'true');
        closeButtonSpan.innerHTML = '&times;';
        closeButton.appendChild(closeButtonSpan);
        messageBox.appendChild(closeButton);
    }();
    
    /**
     * Show the overlay element.
     * 
     * @return {undefined}
     */
    this.show = function () {
        container.style.display = "block";
    };
    
    /**
     * Hide the overaly element.
     * 
     * @param int timeout in ms
     * @return {undefined}
     */
    this.hide = function (timeout) {
        timeout = typeof timeout !== 'undefined' ? timeout : 0;
        setTimeout(function () {container.style.display = "none"; }, timeout);
        
    };
    
    /**
     * Set the message of the messageBox.
     * 
     * @param string message
     * @return {undefined}
     */
    this.setMessage = function(message, type) {
        type = typeof type !== 'undefined' ? type : 'info';
        messageBox.innerHTML = message;
        messageBox.className = 'bg-' + type + ' text-' + type; 
    };
};


